// Modules
import videojs from 'video.js';
import qualityLevels from 'videojs-contrib-quality-levels';
import qualitySelector from "videojs-hls-quality-selector";
import setupCustomElements from './setup_custom_elements';
import setupNextLessonAutoplay from './setup_next_lesson_autoplay';
import setupMobileTextTracks from './setup_mobile_text_tracks';
import sendSegmentPresalesTrailerPlayed from "./setup_segment_trailer_tracking";
import {
  setupShowreelPlayer,
  setupAutoplayAfterFirstPlay,
} from './setup_showreel_player';
import setupCtaOverlay from './setup_cta_overlay';
import playWhenVisible from './setup_play_when_visible';

// import setupHomepageHeroMuteToggle from './setup_homepage_hero_mute_toggle';

// Resources
import {
  runAtInterval,
  isMobileDevice,
  getMobileOperatingSystem,
  videoHasCaptions
} from './helpers';
import setupVolumeSaving from './setup_volume_saving';
import setupPlaybackSpeedSetting from "./setup_playback_speed_setting";
import setupCaptionsSaving from './setup_captions_saving';
import { firstTrackWithSrc } from './helpers/text_tracks';

// Elements
const SELECTOR_LESSONS_PROGRESS_BAR = '.js-lessons-progress-bar';
const SELECTOR_COURSE_PROGRESS_BAR = '.js-course-progress-bar';

videojs.registerPlugin('qualityLevels', qualityLevels);

export function setupPlayer(selector) {
  //videojs.Hls.GOAL_BUFFER_LENGTH = 10;
  //videojs.Hls.MAX_GOAL_BUFFER_LENGTH = 10;
  //videojs.Hls.MAX_BUFFER_SIZE = 0;
  const options = {
    volume_saver: true,
    textTrackSettings: false,
    controlBar: {
      children: {
        progressControl: true,
        playToggle: true,
        volumePanel: { inline: true },
        currentTimeDisplay: true,
        timeDivider: true,
        title: true,
        durationDisplay: true,
        playbackRateMenuButton: true,
        subsCapsButton: true,
      },
    },
    loadingSpinner: !selector.classList.contains('bm-hero__bg-video'),
    preload: 'auto',
    playbackRates: [0.5, 1, 1.5, 2],
    html5: {
      nativeAudioTracks: false,
      nativeVideoTracks: false,
      nativeTextTracks: videojs.browser.IS_IPHONE,
      hls: {
        //limitRenditionByPlayerDimensions: false,
        smoothQualityChange: true,
        bandwidth: 70594304,
        // Overriding native causes flicker on Safari.
        // Later version of VideoJS will default to IS_ANY_SAFARI
        // so we can remove at some point in the future
        // https://github.com/videojs/http-streaming/issues/505
        // Try to set false on all bg
        overrideNative: !videojs.browser.IS_SAFARI,
      },
    },
    userActions: {
      hotkeys: function (event) {
        if (event.key === "c" || event.key === "C") {
          this.player()
            .el()
            .querySelector("button.vjs-subs-caps-button")
            .focus();
        }
      },
    },
  };

  // Remove autoplay if it's on a mobile device.
  // If autoplay is present on mobile, the error thrown
  // that blocks autoplaying also prevents the control bar
  // on instantiation, so removing beforehand
  if (isMobileDevice() && !$(selector)[0].hasAttribute("muted")) {
    $(selector).removeAttr("autoplay");
  }

  // PLAYER INIT
  const playback_session_id = Date.now();
  const player = videojs(selector, options, function onPlayerReady() {});
  setupVolumeSaving(player);
  // VideoJS picks a prefered track and sets it to visible
  // so we need to cancel that...
  player.ready(() => {
    const track = firstTrackWithSrc(player);
    if (track) {
      track.mode = 'disabled';
    }
  });
  // ... before restoring whatever visibility is saved, if any
  setupCaptionsSaving(player);

  if (process.env.NODE_ENV !== 'production') {
    window.player = player;
  }
  const videoEl = $(player.el()).find('video')[0];
  setupPlaybackSpeedSetting(player, videoEl);

  // Set aria title for video
  const videoTitle = player.el().dataset.ariaTitle;
  if (videoTitle != undefined) {
    player.bigPlayButton.controlTextEl_.textContent = `Play ${videoTitle}`;
    player.bigPlayButton.el().setAttribute('title', `Play ${videoTitle}`);
  }

  // Set vjs-poster background image whilst video element keeps it's transparent gif
  $(player.posterImage.el()).css(
    'background-image',
    videoEl.style.backgroundImage
  );

  // Resume user progress.
  // iOS doesn't have access to currentTime when 'play' fires so using 'canplaythrough' instead.
  // 'loadedmetadata', 'loadeddata' & 'canplay' all prove unreliable on different connections speeds
  // and can introduce a bug where video duration is cut when turbolinks restores a page
  const setTimeEvent =
    getMobileOperatingSystem() === 'iOS' ? 'canplaythrough' : 'play';
  let isFirstPlay = true;

  player.on(setTimeEvent, function() {
    if (
      isFirstPlay &&
      player.el().dataset.loadAtTime != undefined
    ) {
      player.currentTime(player.el().dataset.loadAtTime);

      isFirstPlay = false;
    }

    // If the time is more than 1 hour, the duration will be
    // too long for the control bar on small screens, so
    // add a class to adjust position
    const duration = player.controlBar.durationDisplay.formattedTime_;
    if (duration.length > 5) {
      $(player.el())
        .find('.vjs-time-control')
        .addClass('vjs-time-control--lg');
    }

    //let initialized = false;
    // Setting time on iPhone/iPad.
    // Scoped to touch enabled devices since IS_IOS, IS_IPAD and IS_IPHONE do not accurately identify those devices.
    //if (videojs.TOUCH_ENABLED) {
    //player.on('canplaythrough', function() {
    //if (initialized) {
    //player.currentTime(player.el().dataset.loadAtTime - 10);
    //initialized = true;
    //}
    //});
    //}
  });

  // Disable 360 and 2K for now
  let qualityLevels = player.qualityLevels();
  qualityLevels.on('addqualitylevel', function(event) {
    let qualityLevel = event.qualityLevel;

    //if (qualityLevel.height >= 360 && qualityLevel.height <= 1080) {

    if (qualityLevel.height >= 360 && qualityLevel.height <= 1080) {
      qualityLevel.enabled = true;
    } else {
      qualityLevel.enabled = false;
    }

    //I though this would help fixing the homepage flickers.
    //if (player.el().dataset.load360 != undefined) {
    //if (qualityLevel.height == 360) {
    //qualityLevel.enabled = true;
    //} else {
    //qualityLevel.enabled = false;
    //}
    //} else {
    //if (qualityLevel.height >= 360 && qualityLevel.height <= 1080) {
    //qualityLevel.enabled = true;
    //} else {
    //qualityLevel.enabled = false;
    //}
    //}
  });

  player.hlsQualitySelector = qualitySelector;
  player.hlsQualitySelector({ displayCurrentQuality: true });

  qualityLevels.on("change", () => {
    $(".vjs-quality-selector").attr(
      "title",
      `Current quality: ${qualityLevels[qualityLevels.selectedIndex].height}p`
    );
  });

  // Observe player element, detecting when 'vjs-fullscreen' class has been removed
  // Using this instead of fullscreen change events due to patchy browser support
  let prevClassState = player.el().classList.contains('vjs-fullscreen');
  const fullscreenClassObserver = new MutationObserver(function(mutations) {
    mutations.forEach(function(mutation) {
      if (mutation.attributeName == 'class') {
        const currentClassState = mutation.target.classList.contains(
          'vjs-fullscreen'
        );
        if (prevClassState !== currentClassState) {
          prevClassState = currentClassState;
          if (!currentClassState) {
            updateProgress();
          }
        }
      }
    });
  });
  fullscreenClassObserver.observe(player.el(), { attributes: true });

  player.on('seeked', updateProgress);
  player.on('pause', updateProgress);
  player.on('ended', updateProgress);
  runAtInterval(player, 10, updateProgress);

  // Setup Segment - Lesson Watch events
  //   TODO: Lesson Watch Started
  //   TODO: Lesson Watch Ended
  //   TODO: Lesson Watch Paused
  //   TODO: Lesson Watch Seeked
  // runAtInterval(player, 10, trackLessonWatchesProgress);

  $.ajaxPrefilter(function(options, originalOptions, xhr) {
    if (!options.crossDomain) {
      const token = $('meta[name="csrf-token"]').attr('content');
      if (token) xhr.setRequestHeader('X-CSRF-Token', token);
    }
  });

  function updateProgress(e) {
    if (player.el().dataset.trackDuration != undefined) {
      const courseId = player.el().dataset.courseId;
      const lessonId = player.el().dataset.lessonId;
      const currentTime = player.currentTime();
      const trigger_type = e != undefined ? e.type : null;

      const url = '/user_progress';
      const data = {
        playback_position: currentTime,
        playback_session_id: playback_session_id,
        course_id: courseId,
        lesson_id: lessonId,
        current_time: currentTime,
        trigger_type: trigger_type
      };

      $.ajax({
        type: 'POST',
        url: url,
        data: data,
        dataType: 'json',
        success: resp => {
          updateProgressUI(resp);
        }
      });
    }
  }

  function updateProgressUI(progresses) {
    const lesson_progress = progresses.lesson_progress;
    const course_progress = progresses.course_progress;
    const sliderProgressBar = $(
      `${SELECTOR_LESSONS_PROGRESS_BAR}[data-lesson-id = '${progresses.lesson_id}']`
    );
    const navProgressBar = $(
      `${SELECTOR_COURSE_PROGRESS_BAR}[data-course-id = '${progresses.course_id}']`
    );

    // Update course dropdown progress
    $(navProgressBar).css('width', `${course_progress}%`);
  }

  // Explicitly set start time to fix safari bug
  //if (isMobileDevice()) {
  //player.on('canplaythrough', myFunc);
  //}
  //function myFunc() {
  //const startTime = $(player.el()).attr('data-load-at-time');

  //if (startTime) {
  //player.currentTime(startTime - 10);
  //player.off('canplaythrough', myFunc);
  //player.play();
  //}

  //$('.vjs-play-control').blur();
  //}

  // Manually remove focus on control button click
  $(document).on('click', '.vjs-icon-placeholder', e => {
    $(e.target)
      .parent('.vjs-control')
      .blur();
    $('.vjs-play-control').blur();
  });

  // When modal is opened, paused the video if it's
  // playing and add attr to track this...
  $(document).on('show.bs.modal', '.modal', () => {
    if ($(player.el()).hasClass('vjs-playing')) {
      player.pause();
      $(player.el()).attr('paused-for-modal', 'true');
    }
  });

  // then resume playing if the attr is present
  $(document).on('hide.bs.modal', '.modal', () => {
    if ($(player.el()).attr('paused-for-modal') === 'true') {
      player.play();
      $(player.el()).removeAttr('paused-for-modal');
    }
  });

  // Setup custom elements and their functinality
  setupCustomElements(player);

  // Plays/pauses video on tap on mobile devices.
  player.el().addEventListener('touchend', event => {
    4;
    // Check if control bar is revealed.
    const isUserActive = player.el().classList.contains('vjs-user-active');

    // Used to prevent the video pausing on playing when other controls are interacted with.
    const isControl =
      event.target.classList.contains('vjs-control') ||
      event.target.classList.contains('vjs-icon-placeholder') ||
      event.target.classList.contains("vjs-menu-item") ||
      event.target.classList.contains("vjs-menu-item-text") ||
      event.target.classList.contains('vjs-big-play-button');

    // Toggle play state if the user is active, a control was not interacted with and if the device is touch enabled.
    if (isUserActive && !isControl && videojs.TOUCH_ENABLED) {
      if (player.paused()) {
        player.play();
      } else if (!player.paused()) {
        player.pause();
      }
    }
  });

  // if (player.el().classList.contains('bm-hero__bg-video')) {
  //   setupHomepageHeroMuteToggle(player);
  // }

  // Setup listener for next lesson autoplay if next lesson
  // is present
  const nextLessonAttr = player.el().dataset.nextLessonPath;

  if (nextLessonAttr && nextLessonAttr.length) {
    setupNextLessonAutoplay(player);
  }

  // If captions are present setup custom text tracks
  if (videoHasCaptions(videoEl)) {
    setupMobileTextTracks(player, videoEl);
  } else {
    // Or remove and reposition fullscreen/next button
    player.controlBar.subsCapsButton.el().classList.add("vjs-hide");
  }

  if (player.el().dataset.showreelPlayer) {
    setupShowreelPlayer(player);
  }

  if (player.el().dataset.autoplayAfterFirstPlay) {
    sendSegmentPresalesTrailerPlayed(player);
    setupAutoplayAfterFirstPlay(player);
  }

  if (player.el().dataset.buyPath) {
    setupCtaOverlay(player);
  }

  if (player.el().dataset.playWhenVisible) {
    playWhenVisible(player);
  }
}

export function revertVideoForCache(vid, container, originalVid) {
  videojs(vid).dispose();
  $(container).append(originalVid);
}
