const SELECTOR_PROGRESS_BAR = '.js-lessons-progress-bar';
const SELECTOR_SLIDER_CONTAINER = ".js-lesson-slider-container";
const SELECTOR_PLAYLIST_CONTAINER = ".js-playlist-container";

export default function setupUserProgress(list, lesson_id) {
  list = JSON.parse(list);

  // Add progress for progress bars
  const percentages = list.map(item => {
    return {
      lessonId: item.lesson_id,
      percentage: item.completed_percentage / 100
    };
  });

  $(SELECTOR_PROGRESS_BAR).each((ind, progressBar) => {
    const currentId = $(progressBar).attr('data-lesson-id');
    const percentage = percentages.filter(
      person => person.lessonId == currentId
    );
    const progressBarElement = $(progressBar)[0];

    if (percentage.length) {
      progressBarElement.style.setProperty(
        "--progress-bar-width",
        `${percentage[0].percentage}%`
      );
    }
  });
  const slider = document.querySelector(SELECTOR_SLIDER_CONTAINER);
  // Scroll to current slide - 1
  const currentItem = slider.querySelector(
    `[data-lesson-id='${lesson_id}']`
  ).previousElementSibling;

  // If we hit an item that is at the top of the list then we don't need to do anything
  if (currentItem == null) return;

  const container = currentItem.closest(SELECTOR_PLAYLIST_CONTAINER);
  const containerPosition = container.getBoundingClientRect().y;
  const itemPosition = currentItem.getBoundingClientRect().y;
  const offset = itemPosition - containerPosition;
  container.scrollTop = offset;
}
