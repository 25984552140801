// This controller is designed to be used as part of a form where all the possible "items"
// have the ability to enable/disable the "button" target by toggling the "disabled" attribute
// of the button and therefore allowing the form to be submitted.

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["item", "button", "section", "hint"];
  static values = {
    steps: {
      type: Boolean,
      default: false,
    },
  };

  declare itemTargets: Array<HTMLInputElement>;
  declare buttonTarget: HTMLInputElement;
  declare sectionTargets: Array<HTMLFormElement>;
  declare stepsValue: boolean;
  declare hintTarget: HTMLDivElement;

  connect() {
    if (this.areAnyItemsActive() && !this.stepsValue) {
      this.buttonTarget.removeAttribute("disabled");
    }

    if (this.stepsValue && this.areAllQuestionsAnswered()) {
      this.buttonTarget.removeAttribute("disabled");
    } else {
      this.hintTarget.removeAttribute("hidden");
    }
  }

  toggle() {
    this.buttonTarget.toggleAttribute("disabled", !this.areAnyItemsActive());
  }

  toggleAll() {
    this.buttonTarget.toggleAttribute(
      "disabled",
      !this.areAllQuestionsAnswered()
    );

    this.hintTarget.toggleAttribute("hidden", this.areAllQuestionsAnswered());
  }

  areAnyItemsActive() {
    return this.itemTargets.some((item) => item.checked);
  }

  areAllQuestionsAnswered() {
    const sections = this.sectionTargets.map((section) =>
      Array.from(
        section.querySelectorAll("input[type='checkbox'], input[type='radio']")
      )
    );

    const items = sections.map((section) =>
      section.some((item: HTMLInputElement) => item.checked)
    );

    return items.every((item: boolean) => Boolean(item));
  }
}
