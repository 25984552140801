import { Controller } from "@hotwired/stimulus";

export default class Reveal extends Controller {
  static targets = ["item"];
  static classes = ["hidden"];

  declare hiddenClass: string;
  declare itemTargets: HTMLElement[];

  toggle() {
    this.itemTargets.forEach((item) => {
      item.classList.toggle(this.hiddenClass);
    });
  }

  show() {
    this.itemTargets.forEach((item) => {
      item.classList.toggle(this.hiddenClass, false);
    });
  }

  hide() {
    this.itemTargets.forEach((item) => {
      item.classList.toggle(this.hiddenClass, true);
    });
  }
}
