function canAccessLocalStorage() {
  const localStorageKey = "bbcm-test";
  try {
    localStorage.setItem(localStorageKey, localStorageKey);
    localStorage.removeItem(localStorageKey);
    return true;
  } catch (e) {
    return false;
  }
}

function getCSRFToken() {
  const csrfTag = document.querySelector(
    "meta[name='csrf-token']"
  ) as HTMLMetaElement;

  if (!csrfTag) return;

  return csrfTag.content;
}

function throttle(callbackFn, limit: number) {
  let wait = false;
  return function () {
    if (!wait) {
      callbackFn();
      wait = true;
      setTimeout(function () {
        wait = false;
      }, limit);
    }
  };
}

function scrollToElementWithOffset(element: HTMLElement, offset = 150) {
  const headerOffset = offset;
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
}

export {
  canAccessLocalStorage,
  getCSRFToken,
  throttle,
  scrollToElementWithOffset,
};
